<template>
  <div class="container-fluid">
    <div class="col-md-12">
      <h4 class="title-in-h">{{info_page.header}}</h4>
    </div>
    <breadcrumb>
      <breadcrumb-item v-for="breadcrumb in breadcrumbs" v-if="breadcrumb.type!=='active'">
        <router-link :to="{path:breadcrumb.path}"  class="color-blue-var">{{breadcrumb.name}}</router-link>
      </breadcrumb-item>
      <breadcrumb-item active v-else>
        <a @click="resetView()" class="cursor-po inter">{{breadcrumb.name}}</a>
      </breadcrumb-item>
    </breadcrumb>

    <div class="row">
      <div class="col-12">
        <card v-loading="screenLoading"  class="card-complete"
              element-loading-customClass="min-height-large"
              element-loading-lock="true"
              :element-loading-text="screenText"
              element-loading-spinner="el-icon-loading">
          <div class="box-item">
            <div class="box-filter" :class="btn_active===4?'opened':'closed'">
              <div class="filter-top pb-3">
                <div class="row">
                  <div class="col-3 pr-0">
                    <template>
                      <div class="box-controls inp-date">
                        <i class="fa fa-chevron-left icon-control" @click="updDate('minus')"></i>
                        <el-date-picker
                          v-model="info_page.start_date"
                          type="date"
                          placeholder="Elige una fecha"
                          format="dd-MM-yyyy"
                          @change="pickDate()"
                        ></el-date-picker>
                        <i class="fa fa-chevron-right icon-control" @click="updDate('add')"></i>
                      </div>
                    </template>
                  </div>

                  <div class="col-8 text-right">
                    <label class="legend">{{$t('reservation.index.search_for')}}:</label>
                    <button
                      class="btn"
                      :class="(btn_active===1 && type_search===2)?'btn-success':''"
                      @click="loadConfig('recent')"
                    >Reservadas recientes</button>

                    <button
                      class="btn "
                      :class="(btn_active===2 && type_search===2)?'btn-success':''"
                      @click="loadConfig('cancelations')"
                    >Canceladas</button>

                    <button
                      class="btn"
                      :class="(btn_active===3 && type_search===2)?'btn-success':''"
                      @click="loadConfig('pending')"
                    >Sin confirmar</button>

                    <button
                      class="btn"
                      :class="(btn_active===4 && type_search===2)?'btn-success':''"
                      @click="changeCollapse()"
                    >{{$t('reservation.index.advanced_search')}}</button>

                  </div>

                  <div class="col-1 pl-0 custom-drop">

                      <el-select placeholder="Select" :value="active_action"
                                 @change="menuActions">

                          <!--<el-option value="name_user">
                            <span style="margin-right: 10px; color: #8492a6; font-size: 17px; line-height: 32px;"  class="fa fa-user"></span>
                            <span>{{currentUser.email}}</span>
                          </el-option>-->
                          <el-option value="download">
                              <span style="margin-right: 10px; color: #8492a6; font-size: 17px; line-height: 32px;"  class="fa fa-file-excel-o"></span>
                              <span>Descargar (.xlsx)</span>
                          </el-option>
                          <!--<a class="dropdown-item">
                                  <i class="fa fa-print mr-2"></i> Imprimir
                                </a>
                                <a class="dropdown-item">
                                  <i class="fa fa-envelope-o mr-2"></i> Enviar por E-mail
                                </a>-->
                      </el-select>




                      <!--<drop-down position="right">
                     <template slot="title">
                       <label class="collapse-action minus-input">Acciones</label>
                     </template>

                     <a class="dropdown-item" @click="exportExcel">
                       <i class="fa fa-file-excel-o mr-2"></i> Descargar (.xlsx)
                     </a>

                    </drop-down>-->
                  </div>
                </div>
              </div>
              <div class="filter-bottom">
                <div class="row">
                  <div class="col-md-4">
                    <label class="legend label-minus">{{$t('reservation.index.filter_for')}}:</label>

                    <fg-input type="text" v-model="filter.search_for" class="select-default">
                      <el-select v-model="filter.search_for">
                        <el-option
                          v-for="option in info_page.search_for"
                          v-if="option"
                          :value="option.value"
                          :label="option.texto"
                        ></el-option>
                      </el-select>
                    </fg-input>
                  </div>
                    <div class="col-md-4">
                        <fg-input class="minus-lbl date-range">
                            <el-date-picker
                                    v-model="filter.date_range"
                                    type="daterange"
                                    placeholder="Inicio"
                                    format="dd-MM-yyyy"
                                    range-separator="a"
                                    start-placeholder="Desde"
                                    end-placeholder="Hasta"
                                    @change="dateRangeSelect($event)"
                            ></el-date-picker>
                        </fg-input>
                    </div>
                  <div class="col-md-2">
                    <fg-input
                      type="text"
                      v-model="filter.reservation_id"
                      :placeholder="$t('reservation.index.id_reserve')"
                    ></fg-input>
                  </div>
                  <div class="col-md-2 pl-0">
                    <fg-input
                      type="text"
                      v-model="filter.name"
                      :placeholder="$t('reservation.index.name')"
                    ></fg-input>
                  </div>
                  <div class="col-md-12">
                    <div class="form-check">
                      <div class="row">
                        <div class="col-6">
                          <label class="legend mr-2">Estado de la reserva:</label>
                          <fg-input type="text" v-model="query_params.reservation_status" class="select-default">
                            <el-select v-model="query_params.reservation_status">
                              <el-option
                                      v-for="(option, key) in status_reserva"
                                      v-if="option"
                                      :value="option.value"
                                      :label="option.texto"
                              ></el-option>
                            </el-select>
                          </fg-input>
                        </div>
                        <label for="payment_hotel" class="form-check-label mr-4">
                          <input
                                  class="form-check-input"
                                  type="checkbox"
                                  id="payment_hotel"
                                  v-model="filter.cbx.payment_hotel"
                          />
                          <span class="form-check-sign"></span>
                          {{$t('reservation.index.payment_hotel')}}
                        </label>
                        <button
                                class="btn button-flt btn-default btn-wd"
                                @click="setReservations()"
                        >Buscar</button>

                      </div>




                      <!-- <label for="cancels" class="form-check-label mr-4">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="cancels"
                          v-model="filter.cbx.canceled"
                        />
                        <span class="form-check-sign"></span>
                        {{$t('reservation.index.cancels')}}
                      </label>
                      <label for="confirms" class="form-check-label mr-4">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="confirms"
                          v-model="filter.cbx.confirmed"
                        />
                        <span class="form-check-sign"></span>
                        {{$t('reservation.index.confirms')}}
                      </label>
                      <label for="inconfirm" class="form-check-label mr-4">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="inconfirm"
                          v-model="filter.cbx.unconfirmed"
                        />
                        <span class="form-check-sign"></span>
                        {{$t('reservation.index.inconfirm')}}
                      </label> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-sm-12">
                <div class="head-table" v-if="type_search===1">
                  <a
                    @click="loadConfig('arrives')"
                    :class="tab_active===1?'text-info':''"
                  >{{$t('reservation.index.arrives')}} ({{arrives}})</a>
                  <a
                    @click="loadConfig('in_hotel')"
                    :class="tab_active===2?'text-info':''"
                  >{{$t('reservation.index.in_stays')}} ({{in_hotel}})</a>
                  <a
                  @click="loadConfig('departures')"
                    :class="tab_active===3?'text-info':''"
                  >{{$t('reservation.index.departures')}} ({{departures}})</a>

                </div>

                <label class="text-legend" v-show="btn_active>=1 && btn_active<=3 ">{{info_page.legend_tip}}</label>

                <el-table class="table-bigboy"
                          style="width: 100%"
                          :data="displayData"
                          :cell-class-name="tableRowClassName"
                          @sort-change="orderBy"
                >
                  <el-table-column min-width="220"  prop="fullname"  :label="$t('reservation.table.guest')" sortable="custom" >

                    <template slot-scope="{row}">
                      <div class="lbl-container">
                        <label>{{row.name}} {{row.last_name}}</label>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column  min-width="80" :label="$t('reservation.table.arrive')" sortable="custom" prop="date_arrival">
                   <template slot-scope="{row}">
                      <div class="lbl-container text-center">
                        <label>{{formatDate(row.date_arrival, 'normal')}}</label>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column min-width="80" :label="$t('reservation.table.departure')" sortable="custom" prop="date_departure">
                   <template slot-scope="{row}">
                      <div class="lbl-container text-center">
                        <label>{{formatDate(row.date_departure, 'normal')}}</label>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column min-width="100" :label="$t('reservation.table.date_reserve') "  sortable="custom" prop="createdAt">
                    <template slot-scope="{row}">
                      <div class="lbl-container text-center">
                        <label>{{formatDate(checkTimeZone(row.createdAt), 'normal')}}</label>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column min-width="100" sortable="custom" prop="room_name" :label="$t('reservation.table.room') ">
                    <template slot-scope="{row}">
                      <div class="lbl-container text-center">
                        <label>{{row.room_data.name}}</label>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column min-width="120" :label="$t('reservation.table.reserva_id') ">
                    <template slot-scope="{row}">
                      <div class="lbl-container text-center">
                        <!-- <label>{{row._id.substring(0, 8)}}</label> -->
                        <label>{{row._id}}</label>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column min-width="120" :label="$t('reservation.table.addons') ">
                    <template slot-scope="{row}">
                      <div class="lbl-container text-center">
                        <div v-if="row.addons && row.addons.length">
                          <label v-for="addon in row.addons" class="w-separator">{{addon.name}}</label>
                        </div>
                        <label v-else>--</label>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column min-width="120" :label="$t('reservation.table.total') " sortable="custom" prop="total">
                    <template slot-scope="{row}">
                      <div class="lbl-container text-center">
                        <!-- <label>{{row._id.substring(0, 8)}}</label> -->
                        <label >${{formatMoney(row.total)}}</label>
                      </div>
                    </template>
                  </el-table-column>

                  <el-table-column min-width="120" label="Canal"  sortable="custom" prop="status">
                    <template slot-scope="{row}">
                      <div class="lbl-container text-center " >
                        <label>{{translateCanal(row)}}</label>
                      </div>
                    </template>
                  </el-table-column>


                  <el-table-column min-width="120" label="Estado"  sortable="custom" prop="status">
                    <template slot-scope="{row}">
                      <div class="lbl-container text-center " >
                        <label>{{statusReserv(row)}}</label>
                      </div>
                    </template>
                  </el-table-column>

                  <el-table-column
                    min-width="120"
                    label="Acciones"
                    v-if="writting_permission"
                  >
                    <template slot-scope="{row}">
                      <div class="text-center">
                        <a
                            v-tooltip.top-center="$t('form.resend')"
                            :class="validateBooking && showResend(row)?'':'invisible'"
                            class="btn-simple btn-link color-default"
                            @click="sendMail(row._id, row.status, row.email)">
                          <i class="fa fa-envelope"></i>
                        </a>

                        <a
                          v-tooltip.top-center="$t('form.show')"
                          class="btn-info btn-simple btn-link color-blue-var ml-3 mr-3"
                          @click="handleShow(row._id)">
                          <i class="fa fa-eye"></i>
                        </a>
                        <a
                          v-tooltip.top-center="$t('form.edit')"
                          class="btn-warning btn-simple btn-link"
                          @click="handleEdit(row._id)"
                          :class="isEditable(row.rate_data, row.status, row.date_arrival)?'':'invisible'">
                          <i class="el-icon-edit"></i>
                        </a>


                      </div>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </div>


            <div class="clearfix"></div>
          </div>
          <div
                  slot="footer"
                  class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <div class="">
              <p class="card-category" v-if="total>0">
                {{$t('form.showing_a')}} {{from + 1}} {{$t('form.showing_b')}} {{to}}
                {{$t('form.showing_c')}} {{total}} {{$t('form.showing_d')}}
              </p>
            </div>
            <l-pagination
                    class="pagination-no-border"
                    v-model="pagination.currentPage"
                    :per-page="pagination.perPage"
                    :total="pagination.total"
            ></l-pagination>
          </div>
        </card>
      </div>
    </div>

    <!-- <div class="box-footer" :class="(info_page.rate_array.length)?'showme':''">
            <div class="box-inside text-center">
                <button class="btn btn-danger btn-wd btn-default ml-1 mr-1" @click="cancelForm">Cancelar</button>
                <button class="btn btn-success btn-wd btn-default ml-1 mr-1"  @click="sendForm()">Guardar</button>
            </div>
    </div>-->
  </div>
</template>
<script>
// Componentes
import swal from 'sweetalert2'
import { DatePicker, Table, TableColumn, Select, Option } from 'element-ui'
import { Pagination as LPagination, Breadcrumb, BreadcrumbItem } from 'src/components/index'
import { vueTopprogress } from 'vue-top-progress'
import XLSX from 'xlsx';
//import XLSX from ' xlsx-style';
import xl from 'excel4node';
import LogFunctions from '../../../js/functions/LogFunctions';
import LogService from '../../../js/services/LogService';

// Librerias
import moment from 'moment-timezone';
import Fuse from 'fuse.js'
import EventBus from "../../../js/helpers/EventBus";

// Api Services
import RoomService from '../../../js/services/RoomService';
import ReservationService from '../../../js/services/ReservationService';
import Settings from "src/js/helpers/Settings";

import ApiFrontService from '../../../services/ApiFrontService';


// instancias de services
const roomService = new RoomService();
const reservationService = new ReservationService();
let settings = new Settings();
const logFunctions = new LogFunctions();
const logService = new LogService();

// vuex
import { mapGetters } from "vuex";
import { format } from 'd3'
import Loading from 'src/pages/Dashboard/Layout/LoadingMainPanel.vue'

export default {
  components: {
    LPagination,
    Breadcrumb,
    BreadcrumbItem,
    [DatePicker.name]: DatePicker,
    vueTopprogress,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn
  },
  computed: {
    ...mapGetters(["currentUser", "selected_property", "writting_permission"]),
    pagedData() {
      return this.tableData.slice(this.from, this.to)
    },
    /***
     * Searches through table data and returns a paginated array.
     * Note that this should not be used for table with a lot of data as it might be slow!
     * Do the search and the pagination on the server and display the data retrieved from server instead.
     * @returns {computed.pagedData}
     */
    queriedData() {
      let result = this.tableData
      if (this.searchQuery !== '') {
        result = this.fuseSearch.search(this.searchQuery)
        this.pagination.total = result.length
      }
      return result.slice(this.from, this.to)
    },
    to() {
      let highBound = this.from + this.pagination.perPage
      if (this.total < highBound) {
        highBound = this.total
      }
      return highBound
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1)
    },
    total() {
      this.pagination.total = this.tableData.length
      return this.tableData.length
    },
      displayData() {

          let result = JSON.parse(JSON.stringify(this.tableData))
          if (!this.tableData || this.tableData.length === 0) return [];

          return result.slice(this.from, this.to)

      },
  },
  data() {
    return {
        log_page:{
            action: null,
            section: null,
            property_id: null,
            data: [],
            date: new Date(),
            object_id: null,
            user_id: null,
        },
        active_action: 'Acciones',
        screenLoading: false,
        screenText: '',
      breadcrumbs: [
        {
          name: this.$t('property.index.home'),
          path: '/admin/properties',
          type: '',
        },
        {
          name: this.$t('reservation.breadcrumb.index'),
            path: '/admin/reservations',
            type: 'active'
        }
      ],
      info_page: {
          original_info:{},
          section_name: 'reservation',
        status: true,
        header: this.$t('reservation.breadcrumb.index'),
        property_id: '',
        start_date: moment(),
        timezoneIs: moment.tz.guess(),
        rooms: [],
        states: ['Sin concluir', 'Realizada', 'Confirmada', 'Cancelada', 'Sin concluir'],

        contract_types: [
            {},
            {
                _id: 1,
                name: 'Solo habitación (EP)',
                status: false,
                filter: true
            },
            {
                _id: 2,
                name: 'Habitación + desayuno',
                status: false,
                filter: true
            },
            {
                _id: 3,
                name: 'Todo incluido (AI)',
                status: false,
                filter: true
            }
        ],
          paymentTypes: [
              {
                  _id:'ARRIVE',
                  name:'Pago en destino'
              },
              {
                  _id:'TDD',
                  name:'TDC / TDD (OPENPAY)'
              },
              {
                  _id:'PAYPAL',
                  name:'Paypal'
              },
              {
                  _id: 'VALIDATECARD',
                  name: 'Verificación de tarjeta',
              },
              {
                  _id: 'FIRSTDATA',
                  name: 'TDC / TDD (FIRSTDATA)',
              },
              {
                  _id: 'STRIPE',
                  name: 'TDC / TDD (STRIPE)',
              }
          ],
        collapse_status: false,
        legend_tip: 'Reservaciones realizadas durante los últimos 7 días.',
        search_for: {
          LLEGADA: {
            value : 1,
            texto :'Fecha de llegada'
          },
          SALIDA: {
            value : 2,
            texto : 'Fecha de salida'
          },
          RESERVA: {
            value : 3,
            texto :'Fecha de reserva'
          },
          CANCELACION: {
            value : 4,
            texto :'Fecha de cancelación'
          },
        },

        countries: [
          {
              'name':'México',
              'arrayNames': ['México', 'Mexico'],
              'value': 'mx'
          },

          {
              'name':'Canadá',
              'arrayNames': ['Canadá', 'Canada'],
              'value': 'ca'
          },

          {
              'name':'Estados Unidos',
              'arrayNames': ['Estados Unidos', 'United States of America'],
              'value': 'us'
          }
      ]
      },
      filter: {
        active: 1,
        date_range: [ new Date(),new Date()],
        advance: null,
        name: null,
        search_for: 1,
        status: 'Todos',
        reservation_id: null,
        no_confirmation: null,
        cbx: {
          unconfirmed: false,
          reservs: false,
          canceled: false,
          confirmed: false,
          payment_hotel: false,
        }
      },
      datePicker: '',
      items: [],
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 500],
        total: 0
      },
      searchQuery: '',
      propsToSearch: ['name'],
      tableData: [],
      fuseSearch: null,
///////////////////////////////////////////////////////////////////////////
      // constantes
      PENDIENTES: 1,
      CONFIRMADAS: 2,
      CANCELADAS: 3,
      status : {
        0 : 'Todos',
        1 : 'Sin confirmar',
        2 : 'Confirmada',
        3 : 'Cancelada'
      },
      status_reserva : {
        TODOS: {
            value : undefined,
            texto :'Todos'
          },
          PENDIENTE: {
            value: 1,
            texto: 'Sin confirmar'
          },
          CONFIRMADA: {
            value: 2,
            texto: 'Confirmada'
          },
          CANCELADA: {
            value: 3,
            texto: 'Cancelada'
          }
      },
      // Estos son los tipos de tados que se toman en cuenta en la consulta
      query_params:{
        reservation_status: undefined,
        payment_type: undefined,
        date_from: {
          type: undefined,
          value: undefined,
        },
        date_to: {
          type: undefined,
          value: undefined,
        }
      },
      // Tenemos 2 formas de buscar la inicial que te da datos del día de hoy (1).
      // La búsqueda avanzada en la que le pones parámetros (2)
      orderSelected:{
          order : 'ascending',
          prop: 'date_arrival'

      },
      type_search: 1,
      TYPE_TABS:1,
      TYPE_BTNS:2,
      tab_active : 1,
      btn_active : 0,
      arrives: 0,
      in_hotel: 0,
      departures: 0,
      section: 'RESERVATIONS',
      today: moment().locale('es'),

    }
  },
  created() {
    this.$store.dispatch('CHANGE_SECTION', this.section);
    this.chargerItems(this.selected_property._id);
    // moment.locale('es');
  },
  mounted() {
    this.fuseSearch = new Fuse(this.tableData, { keys: ['name'] });
    this.changeProperty();
    this.checkedAction();
  },
  methods: {

    changeProperty() {
      EventBus.$on('CHANGE_PROPERTY', (property_id) => {
          if(this.$route.name==='Reservations'){
              //this.$refs.topProgress.start();
              this.chargerItems(property_id);
          }
      })
    },
    checkedAction(){
        EventBus.$on('CHECKED_VIEW', (checked) => {
            console.log('CHECKED_VIEW contract', this.records.length);
            if(this.records.length){
                this.$store.dispatch('CHANGES_ACTIVES', true);
            }else{
                this.$store.dispatch('CHANGES_ACTIVES', false);
            }
        })
    },
    async chargerItems(property_id) {
        //this.initLoading();
        this.initLoading();
      this.info_page.start_date = moment().locale('es');
      let today =  this.today;


      // Seteamos la fecha de hoy
      this.query_params.date_from.value = today.format('YYYY-MM-DD');
      this.query_params.date_to.value = today.format('YYYY-MM-DD');

      // Obtenemos todas las habitaciones
      let rooms = await roomService.getRooms(true, property_id)
      rooms = rooms.data.data.rooms;
      this.info_page.rooms = rooms;

      // Ponemos rango de hoy menos 7 días
      this.setLasDaysRange();

      await this.setTabsCounts();

        this.configLogs(property_id, this.info_page.section_name);
      //this.closeLoading()

    },
      async createLog(object_id, action, data = []){
          let paramsLog= this.log_page;
          paramsLog.object_id = object_id;
          //paramsLog.section = logFunctions.findSection(this.info_page.section_name)._id
          paramsLog.action = action;
          paramsLog.data = data;
          paramsLog.date = moment().format('YYYY-MM-DD HH:mm:ss');
          await logService.create(paramsLog);
      },
      configLogs(property_id, section_name){
          this.log_page.section = logFunctions.findSection(section_name)._id
          this.log_page.property_id = property_id;
          this.log_page.user_id = this.currentUser._id;
          this.log_page.action = 2;
          this.log_page.date = moment().format('YYYY-MM-DD HH:mm');
      },
    setLasDaysRange(){
      let today =  moment(Object.assign({},  this.today));
      let last_week =  moment(Object.assign({},  this.today));

      // Ponemos rango de hoy menos 7 días
      this.filter.date_range = [
        last_week.subtract(7, 'day'),
        today
      ];
      // console.log('desde',this.filter.date_range[0])
      // console.log('hasta',this.filter.date_range[1])
    },
    async setTabsCounts(){
      // Obtenemos las estancias
      this.setInHotelQuery();
      let in_hotel = await this.setReservations(false);
      this.in_hotel = in_hotel.length;

      // obtenemos las salidas
      this.setDeparturesQuery();
      let departures = await this.setReservations(false);
      this.departures = departures.length;

      // Seteamos el query para que tenga los parámetros de las llegadas de hoy.
      this.setArriveQuery();
      let reservations = await this.setReservations();
      // Obtenemos cantidad de estancias actuales
      this.arrives = reservations.length;
    },
    /**
     *  CONFIGURACIONES PRECARGADAS
     */
    setArriveQuery(){
      this.query_params.reservation_status = this.CONFIRMADAS; // revisar si cambiamo por confirmadas
      this.query_params.date_from.type = 'date_arrival_from';
      this.query_params.date_to.type = 'date_arrival_to';
      this.tab_active = 1;
      this.checkType(this.TYPE_TABS);
    },
    setInHotelQuery(){
      this.query_params.reservation_status = this.CONFIRMADAS; // revisar si cambiamo por confirmadas
      this.query_params.date_from.type = 'date_departure_from';
      this.query_params.date_to.type = 'date_arrival_to';
      this.tab_active = 2;
      this.checkType(this.TYPE_TABS);
    },
    setDeparturesQuery(){
      this.query_params.reservation_status = this.CONFIRMADAS; // revisar si cambiamo por confirmadas
      this.query_params.date_from.type = 'date_departure_from';
      this.query_params.date_to.type = 'date_departure_to';
      this.tab_active = 3;
      this.checkType(this.TYPE_TABS);
    },
    // buttons
    setRecentQuery(){
      //
      this.setLasDaysRange();
      this.dateRangeSelect();
      this.query_params.reservation_status = undefined;
      this.query_params.payment_method = undefined;
      this.filter.cbx.payment_hotel = undefined;
      this.query_params.date_from.type = 'created_at_from';
      this.query_params.date_to.type = 'created_at_to';
      this.btn_active = 1;
      this.checkType(this.TYPE_BTNS);
      this.filter.search_for = this.info_page.search_for.RESERVA.texto;
    },
    setCancelationsQuery(){
      //
      this.setLasDaysRange();
      this.dateRangeSelect();
      this.query_params.reservation_status = this.CANCELADAS;
      this.query_params.payment_method = undefined;
      this.filter.cbx.payment_hotel = undefined;
      this.query_params.date_from.type = 'cancelled_at_from';
      this.query_params.date_to.type = 'cancelled_at_to';
      this.btn_active = 2;
      this.checkType(this.TYPE_BTNS);
      this.filter.search_for = this.info_page.search_for.CANCELACION.texto;

    },
    setPendingQuery(){
      //
      this.setLasDaysRange();
      this.dateRangeSelect();
      this.query_params.reservation_status = this.PENDIENTES;
      this.query_params.date_from.type = 'created_at_from';
      this.query_params.date_to.type = 'created_at_to';
        this.filter.cbx.payment_hotel = true;

        this.btn_active = 3;
      this.checkType(this.TYPE_BTNS);
      this.filter.search_for = this.info_page.search_for.RESERVA.texto;

    },

    //cargar configuración
    async loadConfig(config){
      switch(config){
        // tabs
        case 'arrives':{
          this.setArriveQuery();
          break;
        }
        case 'in_hotel':{
          this.setInHotelQuery();
          break;
        }
        case 'departures':{
          this.setDeparturesQuery();
          break;
        }
        // btns
        case 'recent':{
            this.info_page.legend_tip = 'Reservaciones realizadas durante los últimos 7 días. ('+this.formatDate(this.filter.date_range[0], 'txt')+' - '+ this.formatDate(this.filter.date_range[1], 'txt') +')';
            await this.setRecentQuery();
          break;
        }
        case 'cancelations':{
            this.info_page.legend_tip = 'Reservaciones canceladas durante los últimos 7 días. ('+this.formatDate(this.filter.date_range[0], 'txt')+' - '+ this.formatDate(this.filter.date_range[1], 'txt') +')';
          this.setCancelationsQuery();
          break;
        }
        case 'pending':{
            this.info_page.legend_tip = 'Reservaciones realizadas sin confirmación de pago durante los últimos 7 días. ('+this.formatDate(this.filter.date_range[0], 'txt')+' - '+ this.formatDate(this.filter.date_range[1], 'txt') +')';
          this.setPendingQuery();
          break;
        }
      }
      await this.setReservations();
        if(config==='recent'){
            this.orderBy({
                order : 'descending',
                prop: 'createdAt'

            });
        }
    },
    checkType(action_type){
      // revisamos si la acción es diferente al actual para hacer cambios
      if(action_type!==this.type_search){
        let today = moment();
        if(action_type===this.TYPE_TABS){
          // cambiamos el tipo
          this.type_search =this.TYPE_TABS;
          // cambiamos las fechas de los filtros por el datepicker
          this.query_params.date_from.value =  this.formatDate(this.info_page.start_date);
          this.query_params.date_to.value =  this.formatDate(this.info_page.start_date);
          this.filter.cbx.payment_hotel = undefined;
          this.btn_active = 0;
          this.query_params.reservation_status = undefined;
          this.setArriveQuery();

        }else if(action_type===this.TYPE_BTNS){
          // cambiamos el tipo
          this.type_search =this.TYPE_BTNS;
          // cambiamos las fechas de los filtros por el range picker
          this.query_params.date_from.value =  this.filter.date_range[0];
          this.query_params.date_to.value =  this.filter.date_range[1];
        }
      }
    },
    // transforma el query a parámetros para el API client
      getParams(){
      let params = {};
      params.property_id = this.selected_property._id;
      params[this.query_params.date_from.type] =  moment(this.query_params.date_from.value, 'YYYY-MM-DD').format('YYYY-MM-DD');
      params[this.query_params.date_to.type] = moment(this.query_params.date_to.value, 'YYYY-MM-DD').format('YYYY-MM-DD');
      if(this.query_params.reservation_status){
        params.status= this.query_params.reservation_status*1;
      }

      if(this.filter.cbx.payment_hotel===true){
        params.payment_method = 'ARRIVE'
      }else{
        delete params.payment_method;
      }
      /*if(this.query_params.reservation_status === 1){
          params.payment_method = 'ARRIVE'
      }*/
      if(this.filter.name!==undefined && this.filter.name!==''){
        params.fullname = this.filter.name;
      }
      if(this.filter.reservation_id!=='' && this.filter.reservation_id!==undefined){
        params.id = this.filter.reservation_id;
      }

      if(this.btn_active===4){
          params = this.updateParams(params);
      }

      return params;
    },
      updateParams(params){

        delete params.created_at_from;
        delete params.created_at_to;

          delete params.date_arrival_from;
          delete params.date_arrival_to;
          delete params.date_departure_from;
          delete params.date_departure_to;
          delete params.cancelled_at_from;
          delete params.cancelled_at_to;

        //let params = {};
         //console.log('1 par ', params);
        let paramsDate = ['', ''];
        let filterType = this.filter.search_for;
        if(filterType === 'Fecha de reserva'){
            filterType = 3;
        }
          this.orderSelected = {
              order : 'ascending',
              prop: 'date_arrival'
          }


        if(filterType === 1){
            paramsDate[0] = 'date_arrival_from';
            paramsDate[1] = 'date_arrival_to';
            this.orderSelected = {
                order : 'ascending',
                prop: 'date_arrival'
            }
        }else if(filterType === 2){
            paramsDate[0] = 'date_departure_from';
            paramsDate[1] = 'date_departure_to';
            this.orderSelected = {
                order : 'ascending',
                prop: 'date_departure'
            }

        }else if(filterType === 3){
            paramsDate[0] = 'created_at_from';
            paramsDate[1] = 'created_at_to';
            this.orderSelected = {
                order : 'ascending',
                prop: 'createdAt'
            }
        }else if(filterType === 4){
            paramsDate[0] = 'cancelled_at_from';
            paramsDate[1] = 'cancelled_at_to';
            this.query_params.reservation_status = 3;
            params.status = 3;
            delete params.payment_method;
        }
          params[paramsDate[0]] =  moment(this.query_params.date_from.value, 'YYYY-MM-DD').format('YYYY-MM-DD');
          params[paramsDate[1]] = moment(this.query_params.date_to.value, 'YYYY-MM-DD').format('YYYY-MM-DD')
         //console.log('2 par ', params);
        return params;
      },
    async setReservations(updateTable = true){
      if(!this.screenLoading){
        this.initLoading();
      }
      this.initLoading();

      let params = this.getParams();
      let reservations = await reservationService.getReservations(params);

      if(reservations.status == 200){
          reservations = reservations.data.data.reservations;
          reservations.map(item=> item.room_name = item.room_data.name);
          reservations.map(item=> item.status = (!item.rate_data || item.rate_data === null)?0:item.status);
          /*reservations.forEach(item=>{
              if(!item.rate_data || item.rate_data === null){
                  item.status = 0;
              }
          })*/
      if(updateTable){
          this.tableData = reservations;
      }
      this.orderBy(this.orderSelected);

          //this.items = reservations.data.data.reservations;
        this.closeLoading();
        return reservations;
      }else{
       //console.log("error");
      }
      return ;
    },
    pickDate() {
      let date_ = this.info_page.start_date;
      this.query_params.date_from.value = this.formatDate(date_);
      this.query_params.date_to.value = this.formatDate(date_);
      this.setTabsCounts();
      this.checkType(this.TYPE_TABS);
      // actualizamos
      this.setReservations();
    },
    updDate(type_act) {
      let date_ = moment(this.info_page.start_date);
      if (type_act === 'add') {
        date_ = date_.add(1, 'day').locale('es');
      } else {
        date_ = date_.subtract(1, 'day').locale('es');
      }
      this.info_page.start_date = date_;
      //TODO: encapsular
      this.checkType(this.TYPE_TABS);
      this.query_params.date_from.value = this.formatDate(date_);
      this.query_params.date_to.value = this.formatDate(date_);
      this.setTabsCounts();
      // actualizamos
      this.setReservations();
    },
    dateRangeSelect(e){
      // console.log('event',e);
      // Seteamos las fechas seleccionadas
      this.query_params.date_from.value = moment(this.filter.date_range[0]).format('YYYY-MM-DD');
      this.query_params.date_to.value = moment(this.filter.date_range[1]).format('YYYY-MM-DD');

    },
    changeCollapse() {
      this.info_page.collapse_status = !this.info_page.collapse_status;
      this.btn_active = 4;
      this.checkType(this.TYPE_BTNS);

        //loadConfig('pending')
    },
    // helpers

    roomInfo(room_id) {
      let rooms = this.info_page.rooms;
      // console.log(rooms);
      let aux = rooms.find(item => item._id === room_id)

      return aux ? aux.name: '';
    },
    formatDate(date, typeD = null) {
      let value_r = null;
      switch (typeD) {
        case 'short':
          value_r = moment(date, 'YYYY-MM-DD').format('DD/MM');
          break;

          case 'm-short':
              value_r = moment(date, 'YYYY-MM-DD').format('DD-MMM-YYYY');
              break;
        case 'normal':
          value_r = moment(date, 'YYYY-MM-DD').locale('es').format('DD-MMM-YYYY');

            value_r = value_r.replaceAll('.', '');
          break;

          case 'time':
              value_r  =  moment(date).utc().format('HH:mm')
              break;
          case 'txt':
              value_r  =  moment(date, 'YYYY-MM-DD').format('D [de] MMMM YYYY')
              break;

          case 'min-short':
              value_r  =  moment(date, 'YYYY-MM-DD').format('DD-MM-YY')
              break;
          case 'excel':
              value_r  =  moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY')
              break;
        default:
          value_r = moment(date, 'YYYY-MM-DD').format('YYYY-MM-DD');
          break;
      }
      return value_r
    },
      tableRowClassName({row, column, rowIndex, columnIndex}) {
          //console.log('tzdetect', row, column, rowIndex, columnIndex);
          if (columnIndex===9 &&  row.rate_data ) {

              if(row.status===2){
                  return 'success-row';
              }
              if(row.status===3){
                  return 'danger-row';
              }
              if(row.status===0){
                  return 'warning-row';
              }

          }else if(columnIndex===9){

              return 'warning-row';
          }
          return '';
      },
      checkTimeZone(date){

          let gTimeZone = settings.getTimezone();
          let tzdetect =  moment(date)
              .utc(gTimeZone)

          //console.log('tzdetect', tzdetect);
        return tzdetect;
         /* var tzdetect = {
              names: moment.tz.names(),
              matches: function(base){
                  var results = [], now = Date.now(), makekey = function(id){
                      return [0, 4, 8, -5*12, 4-5*12, 8-5*12, 4-2*12, 8-2*12].map(function(months){
                          var m = moment(now + months*30*24*60*60*1000);
                          if (id) m.tz(id);
                          // Compare using day of month, hour and minute (some timezones differ by 30 minutes)
                          return m.format("DDHHmm");
                      }).join(' ');
                  }, lockey = makekey(base);
                  tzdetect.names.forEach(function(id){
                      if (makekey(id)===lockey) results.push(id);
                  });
                  return results;
              }
          };*/
      },
    // Botones
    handleEdit(id) {

        let routeData = this.$router.resolve({name: 'EditReservation', params: {id: id}});
        window.open(routeData.href, '_blank');
     /* this.$router.push({
        name: 'EditReservation',
        params: {
          id: id
        }
      })*/
    },
      namecomplete(row, column){

        return row.name+' '+row.last_name;

      },
      dateArrive(row, column){

          return this.formatDate(row.date_arrival, 'm-short');

      },

      showResend(row){

        const today = moment().startOf('day');
        const fecha = moment(this.formatDate(row.date_arrival))
        const esPosterior = fecha.isSameOrAfter(today);
        console.log('esPosterior', esPosterior, fecha, today);

        let returnBool = false;
        if((row.status===2 || (row.status===1 && row.payment_method === 'ARRIVE')) && esPosterior){
            returnBool = true;
        }



          //row.status = 0;
          return returnBool
      },



      statusReserv(row){


          if((row.payment_method === 'PAYPAL' || row.payment_method === 'FIRSTDATA' || row.payment_method === 'TDD'  || row.payment_method === 'STRIPE') && row.payment_status === false && row.status===1){
              //row.status = 0;
              return 'Sin concluir'
          }

        if(row.rate_data && row.rate_data !== null){
            return this.status[row.status]
        }

          //row.status = 0;
          return 'Sin concluir'
      },
      roomReserv(row){
          if(row.rate_data){
              row.room_name = row.room_data.name;
              return row.room_data.name;
          }else{
              row.room_name = '--';
              return '--'
          }
      },
      paymentType(value_){

          let types_p = this.info_page.paymentTypes;

          let aux = types_p.find(typs =>  typs._id === value_)

          return aux.name
      },

      vigencyRestant(startDate, endDate){
          startDate = moment(startDate, 'YYYY-MM-DD');
          endDate = moment(endDate, 'YYYY-MM-DD');
          return (startDate.diff(endDate, 'days')*(-1));
      },
      exportExcel2(){
          var wb = new xl.Workbook();

          // Add Worksheets to the workbook
          var ws = wb.addWorksheet('Sheet 1');
          // Create a reusable style
          var style = wb.createStyle({
              font: {
                  color: '#FF0800',
                  size: 12,
              },
              numberFormat: '$#,##0.00; ($#,##0.00); -',
          });

          // Set value of cell A1 to 100 as a number type styled with paramaters of style
          ws.cell(1, 1)
              .number(100)
              .style(style);

          // Set value of cell B1 to 200 as a number type styled with paramaters of style
          ws.cell(1, 2)
              .number(200)
              .style(style);

          // Set value of cell C1 to a formula styled with paramaters of style
          ws.cell(1, 3)
              .formula('A1 + B1')
              .style(style);

          // Set value of cell A2 to 'string' styled with paramaters of style
          ws.cell(2, 1)
              .string('string')
              .style(style);

          // Set value of cell A3 to true as a boolean type styled with paramaters of style but with an adjustment to the font size.
          ws.cell(3, 1)
              .bool(true)
              .style(style)
              .style({font: {size: 14}});

          wb.write('Excel.xlsx');
      },
      async exportExcel(){
          /* convert state to workbook */
          let results = [...this.tableData]
          let dataNew = [];

          if(results.length === 0){
              swal({
                  type: 'warning',
                  text: 'No hay reservaciones para descargar',
                  confirmButtonClass: 'btn btn-success btn-fill',
                  buttonsStyling: false
              })
              return true;
          }
          results.forEach(dataResponse=>{
            //console.log('dataResponse', dataResponse);
              let dataText = {
                  'hotel': this.selected_property.name,
                  'code': dataResponse._id,
                  'code_confirmation': dataResponse._id,
                  'guest': dataResponse.fullname,
                  'date': this.formatDate(dataResponse.createdAt, 'excel'),
                  'time': this.formatDate(this.checkTimeZone(dataResponse.createdAt), 'time'),
                  'lasted_update': this.formatDate(dataResponse.rate_data.updatedAt, 'excel'),
                  'arrive': this.formatDate(dataResponse.date_arrival, 'excel'),
                  'departure': this.formatDate(dataResponse.date_departure, 'excel'),
                  'nigths': this.vigencyRestant(dataResponse.date_arrival, dataResponse.date_departure),
                  'rooms': 1,
                  'adults': dataResponse.rate_data.adults,
                  'children': dataResponse.rate_data.children,
                  'jrs': dataResponse.rate_data.jrs,
                  'infantes':  dataResponse.rate_data.infants,
                  'room_name': dataResponse.room_data.name,
                  'email': dataResponse.email,
                  'phone': dataResponse.phone,
                  'address': dataResponse.address,
                  'city': dataResponse.city,
                  //'state': '--',
                  'zc': dataResponse.zc,
                  'country': this.translateCountry(dataResponse.country),
                  'status': this.statusReserv(dataResponse),
                  'meal_plan': this.info_page.contract_types[dataResponse.rate_data.meal_plan].name,
                  'rateplan': (dataResponse.rateplan_data!==undefined)?dataResponse.rateplan_data.name:'--',
                  'addons': (dataResponse.addons!==undefined && dataResponse.addons.length)?this.addonsAdded(dataResponse.addons):'--',
                  //'total': this.formatMoney(dataResponse.total),
                  'total_reserva': (dataResponse.subtotal+dataResponse.taxes),
                  'total_addons': (dataResponse.total_addons!==undefined)?dataResponse.total_addons:0,
                  'total': dataResponse.total,
                  'currency': dataResponse.currency,
                  'promotion': this.info_promotion(dataResponse.rate_data),
                  'landing_page': (dataResponse.landing_id)?dataResponse.landing_data.name:'--',
                  //'cod_promotion': '--',
                  //'comision': '--',
                  'method': this.paymentType(dataResponse.payment_method),
                  'payment_id':(dataResponse.payment_id && (dataResponse.payment_method === 'TDD' || dataResponse.payment_method === 'PAYPAL' || dataResponse.payment_method === 'FIRSTDATA' ||  dataResponse.payment_method === 'STRIPE'))?dataResponse.payment_id:'--',
                  'channel': (dataResponse.channel!==undefined)?this.translateCanal(dataResponse):'Directa',
                  'ratetiger_id': (dataResponse.ratetiger_id!==undefined)?dataResponse.ratetiger_id:null,
                  'unique_id': (dataResponse.unique_id!==undefined)?dataResponse.unique_id:null
              };
              if(dataResponse.promo_data.length){
                  dataResponse.promotion = dataResponse.promo_data[0].name;
                  dataResponse.cod_promotion = dataResponse.promo_data[0]._id;
              }


              dataNew.push(dataText);
          })

          //return;

          // 'state', 'cod_promotion', 'comision',
          let data = XLSX.utils.json_to_sheet(dataNew,
              {
                  header: ['hotel', 'code', 'code_confirmation', 'guest', 'date', 'time', 'lasted_update',
                  'arrive', 'departure', 'nigths', 'rooms', 'adults', 'children', 'jrs', 'infantes', 'room_name', 'email', 'phone',
                  'address', 'city', 'zc', 'country', 'status', 'meal_plan', 'rateplan', 'addons','total_reserva','total_addons', 
                  'total',  'currency', 'promotion', 'landing_page',  'method', 'payment_id', 'channel', 'ratetiger_id', 'unique_id']
            },  { cellNF : true ,  cellText : false })
         //console.log('data', data);
          data['A1'].v = 'Hotel'
          data['B1'].v = 'Cod reservación'
          data['C1'].v = 'Cod confirmación'
          data['D1'].v = 'Huesped'
          data['E1'].v = 'Fecha de reservación'
          data['F1'].v = 'Hora reservación'
          data['G1'].v = 'Ultima modificación'
          data['H1'].v = 'Fecha ingreso'
          data['I1'].v = 'Fecha salida'
          data['J1'].v = 'Noches'
          data['K1'].v = 'N Hab'
          data['L1'].v = 'Adultos'
          data['M1'].v = 'Juniors'
          data['N1'].v = 'Niños'
          data['O1'].v = 'Infantes'
          data['P1'].v = 'Habitación'
          data['Q1'].v = 'Correo'
          data['R1'].v = 'Número de teléfono'
          data['S1'].v = 'Dirección'
          data['T1'].v = 'Ciudad'
          data['U1'].v = 'Cod Postal'
          //data['U1'].v = 'Cod Postal'
          data['V1'].v = 'País'
          data['W1'].v = 'Estado reserva'
          data['X1'].v = 'Tipo de contrato'
          data['Y1'].v = 'Contrato'
          data['Z1'].v = 'Servicios extra agregados'
          data['AA1'].v = 'Total de la reserva'
          data['AB1'].v = 'Total servicios extra'
          data['AC1'].v = 'Total'
          data['AD1'].v = 'Moneda'
          data['AE1'].v = 'Promoción'
          data['AF1'].v = 'Landing Page'
          data['AG1'].v = 'Método'
          data['AH1'].v = 'ID Transacción'
          data['AI1'].v = 'Canal'
          data['AJ1'].v = 'ID RATETIGER'
          data['AK1'].v = 'UNIQUE ID RATETIGER'

          //data['AC1'].v = 'Cod Promoción'
          //data['AD1'].v = 'Comisión'

          /*
          ; // delete old formatted text if it exists
          XLSX.utils.format_cell(data["Y1"]); // refresh cell*/

          /*data["Y"].z = "$0.00";
          delete data["Y"].w
          XLSX.utils.format_cell(data["Y"])
          console.log(data["Y"].w); // '$1.00'*/
          const currency = '$0.00'

          const workbook = XLSX.utils.book_new()
          //var workbook = XLSX.readFile('/static/libro_base.xlsx');
          const filename = 'Reservaciones - '+ this.formatDate(moment(), 'min-short');

          /*await this.createLog(results[0]._id, 1, [{
              parameter: 'email',
              old: '',
              new: 'Descargó reservaciones'
          }]);*/

          this.formatColumn(data, 'AA', currency)
          this.formatColumn(data, 'AB', currency)
          this.formatColumn(data, 'AC', currency)
          //this.get_header_row(data)
          XLSX.utils.book_append_sheet(workbook, data, filename)
          XLSX.writeFile(workbook, `${filename}.xlsx`, )

      },

      get_header_row(sheet) {
          let S = {
              fill: {
                  patternType: "none", // none / solid
                  fgColor: {rgb: "FF000000"},
                  bgColor: {rgb: "FFFFFFFF"}
              },
              font: {
                  name: 'Times New Roman',
                  sz: 16,
                  color: {rgb: "#FF000000"},
                  bold: false,
                  italic: false,
                  underline: false
              },
              border: {
                  top: {style: "thin", color: {auto: 1}},
                  right: {style: "thin", color: {auto: 1}},
                  bottom: {style: "thin", color: {auto: 1}},
                  left: {style: "thin", color: {auto: 1}}
              }
          }
          var headers = [];
          var range = XLSX.utils.decode_range(sheet['!ref']);
          var C, R = range.s.r; /* start in the first row */
          /* walk every column in the range */
          for(C = range.s.c; C <= range.e.c; ++C) {
              var cell = sheet[XLSX.utils.encode_cell({c:C, r:R})] /* find the cell in the first row */

              var hdr = S + C; // <-- replace with your desired default
              cell.s = S;
              if(cell && cell.t) hdr = XLSX.utils.format_cell(cell);

              headers.push(hdr);
          }
          return headers;
      },
      formatColumn(ws, col, fmt) {
          var C = XLSX.utils.decode_col(col); // 1

          /* get worksheet range */
          var range = XLSX.utils.decode_range(ws['!ref']);
          for(var i = range.s.r + 1; i <= range.e.r; ++i) {
              /* find the data cell (range.s.r + 1 skips the header row of the worksheet) */
              var ref = XLSX.utils.encode_cell({r:i, c:C});
              /* if the particular row did not contain data for the column, the cell will not be generated */
              if(!ws[ref]) continue;
              /* `.t == "n"` for number cells */
              if(ws[ref].t != 'n') continue;
              /* assign the `.z` number format */
              ws[ref].z = fmt;
          }
      },

      info_promotion(data){
        let returnText = '';
        if(data.promos!==undefined && data.promos.length){
            data.promos.forEach((promotion, idx)=>{
                returnText += promotion.name;

                if(idx+1<data.promos.length){
                    returnText += ' / ';
                }
            })
        }else{
            returnText = '--';
        }
        return returnText;
      },


      formatMoney(value){
          return this.numberWithCommas(Math.round(value))
      },
      numberWithCommas(x) {
          return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },

      resetView(){

          this.chargerItems(this.selected_property._id);
      },
      orderBy(hInfo){
          let orderIs = hInfo.order;
          let typeColumn = hInfo.prop;
          let tableData = this.tableData;
          let typeText = ['fullname', 'room_name'];
          let typeDate = ['date_arrival', 'date_departure', 'createdAt', 'status', 'total'];



          if(tableData.length>0){

              if(typeText.indexOf(typeColumn)>=0){
                  tableData.sort((a,b) => this.compareObjects(a, b, typeColumn, orderIs));
              }
              if(typeDate.indexOf(typeColumn)>=0){
                  tableData.sort((a,b) => this.sortByDate(a, b, typeColumn, orderIs));
              }
              /*if(){

              }*/

              /*if(orderIs==='ascending'){
                  //this.compareObjects();

              }else if(orderIs==='descending'){

                  //tableData.sort((a,b) => (a[typeColumn] > b[typeColumn]) ? -1 : ((b[typeColumn] > a[typeColumn]) ? 1 : 0));
              }*/

              this.pagination.currentPage = 1;
          }
         //console.log('ordered:', tableData);
          this.tableData = tableData

      },


      translateCountry(country){
                let auxString = country;
                let countries = this.info_page.countries;
                let aux = countries.find(item => item.value === country)
                if(aux){
                    auxString =  aux.name
                }

                return auxString
            },
      translateCanal(reservation){
          let channel = 'Directa';

          if(reservation.channel && reservation.channel==='ratetiger'){
              channel = 'Rate Tiger';
          }
          return channel;
      },

      sortByDate( object1, object2, key, orderIs = 'ascending') {
          const obj1 = object1[key]
          const obj2 = object2[key]
          let comparison = 0;

          if ( obj1 < obj2 ){
              comparison = -1;
          }
          if ( obj1 > obj2 ){
              comparison = 1;
          }

          if(orderIs==='descending'){
              return comparison * -1
          }
          return comparison
      },
      compareObjects(object1, object2, key, orderIs = 'ascending') {
          const obj1 = object1[key].toUpperCase()
          const obj2 = object2[key].toUpperCase()
          let comparison = 0;

          if (obj1 < obj2) {
              comparison = -1
          }
          if (obj1 > obj2) {
              comparison =  1
          }

          if(orderIs==='descending'){
              return comparison * -1
          }

          return comparison

      },



      renderheader (h, {column}) {
       //console.log('data', h, {column});
          //return  (<el-button>)
      },




    handleShow(id) {


        let routeData = this.$router.resolve({name: 'ViewReservation', params: {id: id}});
        window.open(routeData.href, '_blank');

    },

      validateBooking(){
       let responseStatus = true;
       if(!this.selected_property.booking_url){
           return false;
       }
          let booking_url =   this.selected_property.booking_url.trim()
       if(booking_url==='' || booking_url===null){
           return false;

       }


       return responseStatus;
      },

      async sendMail(id, statusReserv, email){
        let type = 'view-email'
        if(statusReserv===3){
            type =  'cancelation-mail';
        }



          let text = 'Enviando Email'
          swal({
              title: text,
              html:'<img src="/static/img/loading.svg" style="width: 100px"/>'
              ,
              showConfirmButton: false,
              buttonsStyling: false
          })
          try {

              let responseMail = await ApiFrontService.sendEmail(this.selected_property.booking_url, id, type)
              if(responseMail.data.status === 200){
                  await this.createLog(id, 1, [{
                      parameter: 'email',
                      old: '',
                      new: 'Email Re-enviado a:'+' '+email
                  }]);
                  let text = responseMail.data.msg;
                  swal({
                      title: text,
                      html:'<img src="/static/img/message.svg" style="width: 100px"/>',
                      showConfirmButton: true,
                      confirmButtonText: 'Aceptar',
                      buttonsStyling: false,
                      confirmButtonClass: 'btn btn-success btn-fill'
                  })

              }
          }catch (e) {
              swal({
                  title: 'Ha ocurrido un error intentalo mas tarde',
                  html:'<img src="/static/img/message.svg" style="width: 100px"/>',
                  showConfirmButton: true,
                  confirmButtonText: 'Aceptar',
                  buttonsStyling: false,
                  confirmButtonClass: 'btn btn-success btn-fill'
              })
          }



      },


      menuActions(value){
          if(value==='download'){
              this.exportExcel()
          }
          //this.active_action = 'Acciones';
         //console.log('value', value);
      },
      initLoading(text = 'Cargando...') {
          this.screenLoading = true;
          this.screenText = text;
      },
      closeLoading(){
          this.screenLoading = false;
      },
      isEditable(rate_data, status, date_arrival){
          let isVisible = true;
          if(rate_data.length === 0){
              isVisible = false;
          }
          let dateNow = moment().format('YYYY-MM-DD');
          let dateArrive = moment(date_arrival, 'YYYY-MM-DD').format('YYYY-MM-DD');

          if(status===3 && dateNow>dateArrive){
              isVisible = false;
          }

          return isVisible;
      },
      addonsAdded(responseItem){
        let responsehtml = '';
          responseItem.forEach((item, idx)=>{
              if(idx === 0){
                  responsehtml =  responsehtml + item.name;
              }else{
                  responsehtml =  responsehtml + ' | ' + item.name;
              }
          })
          return responsehtml;
      }
  },
}
</script>

<style lang="scss">
  .cursor-pointer{
    cursor: pointer;
  }
  .btn-link{
    font-size: 18px;
  }
.box-filter {
  display: inline-block;
  width: 100%;
  .filter-bottom {
    position: absolute;
  }
  &.opened {
    height: 130px;
    transition: height 1s ease-in;
    //transform: translateY(0);
  }
  &.closed {
    height: 45px;
    transition: height 1s ease-out;
    //display: none;
    //transform: translateY(-100%);
  }

  .btn-advanced {
    border: 1px solid #447df7;
    color: #447df7;
    border-radius: 30px;
    &:active {
      outline: none;
      text-decoration: none;
      margin-right: 2%;
    }
    &.active {
      background-color: #447df7;
      color: #ffffff;
    }
  }
  li.dropdown {
    width: 100%;
    display: inline-block;
    line-height: 30px;
    a.dropdown-toggle{
      display: inline-block;
    }
    a {
      padding: 0;
      label {
        text-transform: none;
        font-size: 14px;
        font-weight: 600;
        color: #909398;
        font-family: Roboto;
      }
      &::after {
        font-size: 14px;
        color: #909398;
        margin-left: 0.455em;
        vertical-align: 0.1em;
      }
    }
  }

  .dropdown-menu {
    border-radius: 0;
    border: 2px solid #fff;
    background: #fff;
    a.dropdown-item {
      border-radius: 0 !important;
      padding: 5px 16px;
    }
    &:after,
    &:before {
      top: -13px;
    }
  }
}
.box-control {
  display: inline-block;
  position: relative;
  z-index: 2;
  background: #fff;
  width: 100%;
  i {
    width: 25px;
  }
}

.box-item {
  position: relative;
  width: 100%;
  display: inline-block;
  white-space: nowrap;
  .head-table {
    width: 100%;
    display: inline-block;
    background-color: #0e0e0e;
    padding: 10px 15px;

    a {
      margin-right: 20px;
      color: #ffffff;
      cursor: pointer;
      &:hover {
        color: #23ccef;
      }
    }
  }
  .cell {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
    display: inline-block;
  }
}
.text-legend{
  width: 100%;
  height: 20px;
  line-height: 1;
  background: #fff;

  border-top: 1px solid#fff;
  margin-bottom: 0 !important;
  font-size: 14px !important;
  text-transform: none !important;
}
.box-filter {
  position: relative;
  .select-default {
    width: auto;
    display: inline-block;
    margin-right: 0;
    .el-input:hover {
      color: #0e0e0e;
      input {
        color: #0e0e0e !important;
        &::-webkit-input-placeholder {
          /* Chrome/Opera/Safari */
          color: #888888;
        }
        &::-moz-placeholder {
          /* Firefox 19+ */
          color: #888888;
        }
        &:-ms-input-placeholder {
          /* IE 10+ */
          color: #888888;
        }
        &:-moz-placeholder {
          /* Firefox 18- */
          color: #888888;
        }
      }
    }
    input.el-input__inner {
      padding: 0 15px 0 7px;
      height: 30px;
      line-height: 30px;
      background: #fff;
      color: #0e0e0e;
    }
    .el-select__caret {
      line-height: 30px;
    }
  }

  .date-range {
    .el-date-editor--daterange {
      padding: 0 10px;
      height: 30px;
      input {
        height: 21px;
      }
    }
  }

  .button-flt {
    border-color: rgba(36, 204, 239, 0.5);
    color: rgba(36, 204, 239, 0.5);
    &.active {
      border-color: rgba(36, 204, 239, 1);
      background-color: rgba(36, 204, 239, 1);
      color: #ffffff;
    }
  }

  .btn{
    background: #fff;
    color: #00AB69;
    border-color: #00AB69;
  }
  .btn-success, .btn-default{
    color: #fff;
  }
  .inp-date {
    border: 1px solid #e3e3e3;
    border-radius: 4px;
    .icon-control {
      width: 12%;
      cursor: pointer;
      top: 2px;
      position: relative;
    }

    .el-date-editor.el-input {
      width: 76% !important;
    }
    .box-controls {
      line-height: 40px;
    }
    .el-input__icon {
      line-height: 20px;
    }
    input {
      width: 100%;
      border: 0;
      height: 28px;
    }
  }
  .label-minus {
    display: inline-block;
    width: auto;
    margin-right: 5px;
  }
  .input-min-size {
    width: calc(100% - 88px);
    margin-right: 10px;
    display: inline-block;
    input {
      padding: 5px 5px;
    }
  }
  .minus-input {
    font-size: 14px;
  }
  .minus-lbl {
    display: inline-block;
    width: auto;
    .el-date-editor {
      padding: 0 5px;
      .el-range-input {
        width: 42%;
      }
    }
    .el-range__icon,
    .el-input__icon {
      line-height: 25px;
    }
    .el-range__close-icon {
      width: 20px;
    }
    .el-range-separator {
      line-height: 27px;
      padding: 0;
      margin: 0 5px;
    }
  }
  .legend {
    text-transform: none;
    color: #0e0e0e;
    font-size: 14px;
  }
  a.collapse-action {
    color: #1d62f0;
  }
  input {
    padding: 5px 10px;
    height: 30px;
    font-size: 13px;
    width: 100%;
  }
  button {
    padding: 5px 7px;
    height: 30px;
    font-size: 13px;
    margin-left: 2%;
  }
}
.el-table .warning-row {
  background: oldlace;
}

.el-table .success-row {
  background: #f0f9eb;
}
.el-table .danger-row{
  background: #ffd5d5;
}
.custom-drop{
    .el-select{
        min-width: auto;
        width: auto;
        top: 0;
        margin-right: 10px;
        font-family: Roboto;
        .el-input__inner{
            border: 0;
            white-space: nowrap;
            margin: 0;
            color: #000;
            background: transparent;
            min-width: 100%;
            padding-left: 0;
            padding-right: 0;
        }
        .el-input__suffix{
            right: -15px;
            top: 5px;
        }
    }
}
  .card{
    min-height: 80vh;
    .label-row{
      white-space: nowrap;
      line-height: 16px;
      margin-bottom: 0;
      float: left;
    }
  }
</style>
